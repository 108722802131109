<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Snowflake Task Status</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
      </v-btn>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :headers="tableColumnHeaders"
      :items="getTaskData"
      item-key="task"
      class="table-rounded"
    >
      <!-- task -->
      <template #[`item.task`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.task }}</span>
            <!-- <span class="text-xs">{{ item.email }}</span> -->
          </div>
        </div>
        <v-chip x-small>
          {{ item.database }}.{{ item.schema }}
        </v-chip>
        <v-chip
          v-if="item.status === 'suspended'"
          class="error ml-3"
          x-small
        >
          {{ 'Suspended' }}
        </v-chip>
      </template>

      <!-- status  -->
      <template #[`item.state`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="18"
              :color="resolveColor(item.state)"
              v-bind="attrs"
              v-on="on"
            >
              {{ resolveIcon(item.state) }}
            </v-icon>
          </template>
          <span>{{ item.error || item.state }}</span>
        </v-tooltip>
      </template>

      <!-- Last Run -->
      <template #[`item.lastRun`]="{item}">
        {{ item.lastRun | moment("from") }}
      </template>

      <!-- Next Run -->
      <template #[`item.nextRun`]="{item}">
        {{ item.nextRun | moment("from") }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line object-curly-newline
/* eslint-disable indent */
import { cronConversion } from '@/functions/dates'
import store from '@/store'
import { mdiAlertCircle, mdiCheckBold, mdiInformation } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment-timezone'

export default {
  setup() {
    const selected = ref([])
    const chipColor = {
      Paid: 'success',
      Unpaid: 'error',
    }

    const resolveIcon = state => {
      if (state === 'SUCCEEDED') return mdiCheckBold
      if (state === 'FAILED') return mdiAlertCircle

      return mdiInformation
    }

    const resolveColor = state => {
      if (state === 'SUCCEEDED') return 'success'
      if (state === 'FAILED') return 'error'

      return 'info'
    }

    function getNextRun(schedule, lastRun) {
      let minutes = null
      let nextRun = null

      // Process Cron
      if (schedule.includes('CRON')) {
        let cronString = schedule.replace('USING CRON ', '')
        const lastIndex = cronString.lastIndexOf(' ')
        cronString = cronString.substring(0, lastIndex)
        const cronSchedule = cronConversion(cronString)
        nextRun = cronSchedule.next().format()
      } else {
        const split = schedule.split(' ')
        const timeType = split[1]
        const value = split[0]
        // eslint-disable-next-line operator-linebreak
        minutes =
          timeType === 'minute'
            ? value
            : timeType === 'hour'
            ? value * 60
            : timeType === 'day'
            ? value * 60 * 24
            : timeType === 'month'
            ? value * 60 * 24 * 30
            : timeType === 'year'
            ? value * 60 * 24 * 30 * 365
            : 1
      }

      if (!nextRun) {
        // eslint-disable-next-line operator-linebreak
        nextRun =
          lastRun && lastRun.scheduledTime
            ? moment(lastRun.scheduledTime).add(minutes, 'minutes').format()
            : moment().add(minutes, 'minutes').format()
      }

      return nextRun
    }

    const getTaskData = computed(() => {
      const tasks = []
      const taskData = store.state.snowflakeData.snowflakeTasks
      console.log('Task Data', taskData)
      taskData.forEach(taskRow => {
        // eslint-disable-next-line object-curly-newline
        const { database, schema, task, schedule, taskState, runs } = taskRow
        const lastRun = runs ? _.maxBy(runs, 'completedTime') : null
        const nextRun = runs ? getNextRun(schedule, lastRun.scheduledTime) : null
        console.log(`Next Run: ${nextRun}`)

        const error = lastRun && lastRun.error ? `${lastRun.runState}: ${lastRun.error}` : null
        const order = lastRun && lastRun.runState === 'FAILED' ? 0 : 1

        tasks.push({
          database,
          schema,
          task,
          state: lastRun ? lastRun.runState : null,
          lastRun: lastRun ? lastRun.scheduledTime : null,
          error,
          nextRun,
          status: taskState,
          order,
        })
      })
      console.log('Tasks', tasks)

      return _.orderBy(tasks, ['order', 'nextRun'])
    })

    const tableColumnHeaders = [
      {
        text: 'Task',
        align: 'start',
        value: 'task',
      },
      { text: 'State', value: 'state' },
      { text: 'Last Run', value: 'lastRun' },
      { text: 'Next Run', value: 'nextRun' },
    ]

    return {
      resolveColor,
      resolveIcon,
      getTaskData,
      selected,
      tableColumnHeaders,
      chipColor,
    }
  },
}
</script>
