<template>
  <v-card>
    <v-row dense>
      <v-col
        class="ma-0"
        sm="6"
      >
        <v-card-title class="align-start">
          <span>Snowflake Users</span>
        </v-card-title>

        <v-card-subtitle class="mb-7">
          <span class="font-weight-semibold text--primary me-1">Users Statistics For {{ dateRangeFilter.name }}</span><v-icon color="success"></v-icon>
        </v-card-subtitle>
      </v-col>
      <v-col
        class="mt-5 mr-3"
        sm="5"
      >
        <v-select
          v-model="dateRangeSelection"
          item-color="primary"
          :items="dateSelectionOptions"
          dense
          :prepend-inner-icon="icons.mdiFilterVariant"
          outlined
          filled
          rounded
          hide-details
          @change="changeDateFilter"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="4"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="data.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ data.icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { colorLight, formatNumbers } from '@/functions'
import store from '@/store'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountGroup,
  mdiCogClockwise,
  mdiCurrencyUsd,
  mdiDotsVertical,
  mdiFilterVariant,
  mdiTrendingUp,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment-timezone'

export default {
  setup() {
    const dateSelectionOptions = computed(() => store.state.dateRangeFilter.map(m => m.name))
    const dateRangeFilter = computed(() => store.getters.dateRangeFilter)
    const dateRangeSelection = ref(dateRangeFilter.value.name)
    const changeDateFilter = value => {
      store.commit('setDateRangeFilter', value)
    }

    const totalSnowflakeUsers = computed(() => {
      const users = store.state.snowflakeData.snowflakeUsers

      return users.length
    })
    const activeSnowflakeUsers = computed(() => {
      let users = store.state.snowflakeData.snowflakeUsers
      console.log('dateRangeFilter', dateRangeFilter.value)
      users = users.filter(f => new Date(f.lastLogin) >= moment(dateRangeFilter.value.startDate).toDate())

      return users.length
    })
    const totalExecutions = computed(() => {
      // eslint-disable-next-line object-curly-newline
      const { objectType, objectName, startDate, endDate } = dateRangeFilter.value
      const getExecutions = store.state.snowflakeData[`snowflakeExecutions${objectType}`]
      const dateFormat = objectType === 'Daily' ? 'YYYYMMDD' : 'YYYYMM'
      const executionsData = getExecutions.filter(
        f =>
          f[objectName] >= moment(startDate).format(dateFormat) && f[objectName] <= moment(endDate).format(dateFormat),
      )

      return _.sumBy(executionsData, `total${dateRangeFilter.value.objectType}Executions`)
    })
    const totalCredits = computed(() => {
      const getCreditConsumption = store.getters['snowflakeData/snowflakeWarehouseConsumptionByFilter']

      return _.sumBy(getCreditConsumption, 'credits')
    })

    const statisticsData = computed(() => [
      {
        title: 'Active Users',
        icon: mdiAccountGroup,
        color: 'primary',
        total: `${formatNumbers(activeSnowflakeUsers.value)} of ${formatNumbers(totalSnowflakeUsers.value)}`,
      },
      {
        title: 'Total Executions',
        icon: mdiCogClockwise,
        color: 'error',
        total: formatNumbers(totalExecutions.value),
      },
      {
        title: 'Credits Consumed',
        icon: mdiCurrencyUsd,
        color: 'warning',
        total: formatNumbers(totalCredits.value),
      },
    ])

    return {
      totalExecutions,
      changeDateFilter,
      colorLight,
      dateRangeSelection,
      dateRangeFilter,
      dateSelectionOptions,
      statisticsData,

      // icons
      icons: {
        mdiFilterVariant,
        mdiTrendingUp,
        mdiDotsVertical,
      },
    }
  },
}
</script>
