<template>
  <v-card>
    <v-card-title class="align-start flex-nowrap">
      <div>
        <p>Consumption by Month</p>
      </div>
      <v-spacer></v-spacer>
      <div class="mb-5 mr-7">
        <p class="text-no-wrap">
          <span class="font-weight-semibold me-1 primary--text">${{ Math.round(consumptionByMonth.currentMonth.expenses).toLocaleString() }}</span>
          <small :class="consumptionByMonth.currentMonth.monthlyChangeIndicator === 'Up' ? 'text-xs error--text' : 'text-xs success--text'">
            <v-icon
              :color="consumptionByMonth.currentMonth.monthlyChangeIndicator === 'Up' ? 'error' : 'success'"
              size="22"
            >
              {{ consumptionByMonth.currentMonth.monthlyChangeIndicator === 'Up' ? icons.mdiChevronUp : icons.mdiChevronDown }}
            </v-icon>
            <span>{{ Math.abs(consumptionByMonth.currentMonth.monthlyChange).toFixed(2) }}%</span>
          </small>
        </p>
      </div>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <!-- Chart -->
    <v-card-text style="margin-top: -50px; padding-bottom: 0px">
      <vue-apex-charts
        height="230"
        :options="chartOptions"
        :series="chartData"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { colorLight, formatNumbers } from '@/functions'
import store from '@/store'
import { getVuetify } from '@core/utils'
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical } from '@mdi/js'
import { computed } from '@vue/composition-api'
import moment from 'moment-timezone'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const $vuetify = getVuetify()
    const { creditCost } = store?.state?.accountProfile?.snowflakeSettings || 3

    const snowflakeContractDetails = computed(() => store.state.snowflakeData.snowflakeContractDetails)

    const creditGoal = computed(() => snowflakeContractDetails.value.perDayCreditAllowance * 30)

    const consumptionByMonth = computed(() => {
      const getMonthlyConsumption = store.state.snowflakeData.snowflakeConsumptionMonthly
      const monthlyConsumption = getMonthlyConsumption.filter(
        f => f.month >= moment().subtract(5, 'month').format('YYYYMM'),
      )

      // const months = monthlyConsumption.map(m => moment(m.month, 'YYYYMM').toDate().toISOString())
      const months = monthlyConsumption.map(m => moment(m.month, 'YYYYMM').format("MMM 'YY"))
      const credits = monthlyConsumption.map(m => Math.round(m.totalMonthlyCredits))
      const expenses = credits.map(m => m * 3)
      const thisMonth = monthlyConsumption.filter(f => f.month === moment().format('YYYYMM'))[0]
      const lastMonth = monthlyConsumption.filter(f => f.month === moment().subtract(1, 'month').format('YYYYMM'))[0]
      const currentMonth = {
        expenses: thisMonth.totalMonthlyCredits * creditCost || 0,
        credits: credits[credits.length - 1],
        monthlyChange:
          ((thisMonth.totalMonthlyCredits - lastMonth.totalMonthlyCredits) / lastMonth.totalMonthlyCredits) * 100 || 0,
        monthlyChangeIndicator: thisMonth.totalMonthlyCredits - lastMonth.totalMonthlyCredits > 0 ? 'Up' : 'Down',
      }

      return {
        months,
        credits,
        expenses,
        currentMonth,
      }
    })

    // const storageByMonth = computed(() => {
    //   const getStorageData = store.state.snowflakeData.snowflakeStorageMonthly
    //   let storageData = getStorageData.filter(f => f.month >= moment().subtract(5, 'month').format('YYYYMM'))
    //   storageData = _.sortBy(storageData, ['month'])

    //   return storageData.map(m => m.totalMonthlyDBBytes)
    // })

    const chartData = [
      {
        name: 'Credits Used',
        type: 'column',

        data: consumptionByMonth.value.credits,
      },
      {
        name: 'Cost Labels',
        type: 'line',

        data: consumptionByMonth.value.credits,
      },
      {
        name: 'Cost Goal',
        type: 'line',

        data: consumptionByMonth.value.credits.map(() => creditGoal.value),
      },
    ]

    const chartOptions = computed(() => {
      const chartOptionsBase = {
        colors: [colorLight($vuetify.theme.currentTheme.primary), colorLight($vuetify.theme.currentTheme.warning)],
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '80%',
            startingShape: 'rounded',
          },
        },
        stroke: {
          colors: ['white'],
          width: [1],
        },
        fill: {
          opacity: 0,
        },
        dataLabels: {
          enabledOnSeries: [1],
          offsetY: -10,
          formatter: (value, opts) => {
            if (opts.seriesIndex === 0) {
              return formatNumbers(value, 0)
            }

            return `$${formatNumbers(value * snowflakeContractDetails.value.creditCost, 0)}`
          },
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
            colors: [$vuetify.theme.currentTheme.primary],
          },
          background: {
            enabled: true,
            opacity: 0.9,
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        annotations: {
          yaxis: [
            {
              y: creditGoal.value,
              offsetY: -8,
              borderColor: $vuetify.theme.currentTheme.primary,
              label: {
                position: 'left',
                offsetX: 110,
                borderColor: $vuetify.theme.currentTheme.primary,
                style: {
                  color: '#fff',
                  background: $vuetify.theme.currentTheme.primary,
                },
                text: `Credit Allowance: ${formatNumbers(creditGoal.value)}`,
              },
            },
          ],
        },
        xaxis: {
          type: 'category',
          categories: consumptionByMonth.value.months,
          labels: {
            style: {
              fontWeight: 'bold',
            },
          },
        },
        yaxis: [
          {
            seriesName: 'Credits Used',
            colors: [$vuetify.theme.currentTheme.primary],
            title: {
              text: 'CREDITS',
              style: {
                color: $vuetify.theme.currentTheme.primary,
                fontWeight: 'bold',
              },
            },
            labels: {
              formatter: value => formatNumbers(value, 0),
              style: {
                colors: $vuetify.theme.currentTheme.primary,
                fontWeight: 'bold',
              },
            },
          },
        ],
      }

      if (creditGoal.value < 1) {
        delete chartOptionsBase.annotations
      }

      return chartOptionsBase
    })

    return {
      consumptionByMonth,
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
