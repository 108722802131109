<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="2"
        class="align-self-end"
      >
        <overview-user-totals-card></overview-user-totals-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        sm="12"
        order="5"
        order-md="3"
      >
        <overview-statistics-card></overview-statistics-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        sm="12"
        order="3"
        order-md="4"
      >
        <overview-consumption-by-month-card></overview-consumption-by-month-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        sm="12"
        order="6"
      >
        <overview-consumption-by-warehouse-card></overview-consumption-by-warehouse-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <overview-schema-changes></overview-schema-changes>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <overview-task-status></overview-task-status>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-const */
/* eslint-disable object-curly-newline */
import OverviewConsumptionByMonthCard from './OverviewConsumptionByMonth.vue'
// eslint-disable-next-line lines-around-comment
import OverviewConsumptionByWarehouseCard from './OverviewConsumptionByWarehouse.vue'
import OverviewSchemaChanges from './OverviewSchemaChanges.vue'
import OverviewStatisticsCard from './OverviewStatisticsCard.vue'
import OverviewTaskStatus from './OverviewTaskStatus.vue'
import OverviewUserTotalsCard from './OverviewUserTotalsCard.vue'

// import { updateProfile } from 'firebase/auth'

// import { functions, httpsCallable } from '../../../firebaseApp'

export default {
  components: {
    OverviewTaskStatus,
    OverviewUserTotalsCard,
    OverviewStatisticsCard,
    OverviewConsumptionByMonthCard,

    OverviewConsumptionByWarehouseCard,
    OverviewSchemaChanges,
  },
  setup() {
    return {}
  },
}
</script>
